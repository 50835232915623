



import React, { useState, useEffect } from 'react'
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl';
import SVG from "react-inlinesvg";
import { useDebounce } from 'use-debounce';
import isEmpty from '../../utils/isEmpty';




const SidebarRangeFilter = ({ addFilter, setUrl, checkedItems, label, field, min, max, step, icon, filterType, _values, currentFilter, showInitial=true, mobile }) => {

    let minInitialValue = 0
    let maxInitialValue = 100

    const findValue = (currentFilter, limit, field) => {
        let ar = currentFilter.filter(x => x.filterfield===field)
        if(limit==='gte'){
            return ar.length === 2 ? ar.find(x => x.filtertype==='gte').filtervalue : minInitialValue
        }
        if(limit==='lte'){
            return ar.length === 2 ? ar.find(x => x.filtertype==='lte').filtervalue : maxInitialValue
        }
    }

    const [ showItems, setShowItems ] = useState(showInitial)

    const  [ value, setValue ] = useState({
        value:{
            min: findValue(currentFilter.filter(x => x.filterfield === field), 'gte', field), 
            max:findValue(currentFilter.filter(x => x.filterfield === field), 'lte', field)
        }
    })
    
    const [ debouncedvalue ] = useDebounce(value, 1000);

    useEffect(() => { checkValues(debouncedvalue) }, [debouncedvalue])

    const checkValues = (newValues) => {

        if(!isEmpty(value)){
            let minNewValue = newValues.value.min
            let maxNewValue = newValues.value.max
            if(findValue(currentFilter, 'gte') != minNewValue || findValue(currentFilter, 'lte') != maxNewValue){
                addFilter({
                    filterfield: field, 
                    filtertype: 'range', 
                    filtervalue: [ minNewValue, maxNewValue ], 
                    filterlabel: [ minNewValue, maxNewValue ],
                    multi: false
                })
            }
        }
    }

    

    useEffect(() => {
        if(findValue(currentFilter.filter(x => x.filterfield === field), 'gte', field) === minInitialValue && findValue(currentFilter.filter(x => x.filterfield === field), 'lte', field) === maxInitialValue){
            setValue({
                value:{
                    min: minInitialValue,
                    max: maxInitialValue
                }
            })
        } 
    }, [currentFilter])

    if(isEmpty(value)) return <></>
    return (
        <div className="woocommerce-widget collections-list-widget pl-3 pr-3 ">
            <div  className="woocommerce-widget-top" onClick={()=>{setShowItems(!showItems)}}>
                {
                    !mobile && <>
                        <span className="icon-wrapper">
                            <i className={`bx ${icon}`}></i> 
                        </span>
                        <h4  className="woocommerce-widget-title">{label}</h4>
                        <SVG
                            className={`${showItems ? "arrow-angle " : "arrow-angle rotate180"}`}
                            height={14}
                            width={14}
                            src={toAbsoluteUrl("/static/icons/up-arrow-angle.svg")}
                        /> 
                    </>
                }
                
            </div>
            <div className={`list-items items-filter range ${!showItems && "hidden"}`}>
                <div className={mobile ? 'mt-3 mb-5 pr-2 pl-2' : ''}>
                    <InputRange
                        formatLabel={value => `${value}%`}
                        maxValue={100}
                        minValue={0}
                        value={value.value}
                        onChange={value => setValue({value:value})} 
                    />
                </div>
            </div>
        </div>
    )
}
export default SidebarRangeFilter