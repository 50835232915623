import React, { useState, useEffect } from 'react'
import StarRatings from 'react-star-ratings';
import { productService } from '../../services'
import CircleSpinner from '../../components/LoadingAnimation/CircleSpinner';
import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl';
import SVG from "react-inlinesvg";

const styleSidebar = {
    maxHeight: '300px',
    overflowY: 'auto',
    paddingRight: '0.5rem',
    marginRight: '-0.75rem'
}


const SidebarStaticRangeFilter = ({data, addFilter, name, setFilter, setUrl, keyName, checkedItems, showInitial, mobile, source, sourceId, prechargedItems, disableSearch, filterType, type, icon, label, currentFilter, field, multi}) => {

    const [ items, setItems ] = useState(false)
    
    const [ showItems, setShowItems ] = useState(showInitial)
    const [ limit, setLimit ] = useState(true)
    const [ search, setSearch ] = useState('')

    const handleChange = selectedOption => {

       
        addFilter({
            filterfield: selectedOption.field, 
            filtertype: selectedOption.type ? selectedOption.type : type, 
            filtervalue: selectedOption.value, 
            filterlabel: selectedOption.label,
            multi: multi
        })
    };

    useEffect(() => {
        (search.length >= 1) && (
            filterItems(search)
        )
    }, [search])


    const fetch = async () => {
        !prechargedItems ? await productService.getFilter(`?source=${source}&sourceId=${sourceId}&target=${name}`)
            .then( response => {
                setItems(response)
            })
        : setItems(prechargedItems)
    }

    const findSelected = (current, filter, icon) => {
        const values = current.map(x => {return parseInt(x.filtervalue, 10)})

        if( JSON.stringify(values)===JSON.stringify(filter.value) ) {
            if(icon) {
                return (
                    <span className='icon-wrapper'>
                        <i className={`bx ${multi ? 'bxs-checkbox-checked' : 'bxs-circle'}`}></i>
                    </span>
                )
            } else {
                return true
            }
        } else {
            if(icon) {
                return (
                    <span className='icon-wrapper'>
                        <i className={`bx ${multi ? 'bx-checkbox' : 'bx-circle'}`}></i>
                    </span>
                )
            } else {
                return false
            }
        }
    }

    useEffect(() => {fetch()}, [])

    return(
        <div className="woocommerce-widget collections-list-widget pl-3 pr-3 ">
            {
                !mobile && <div  className="woocommerce-widget-top" onClick={()=>{setShowItems(!showItems)}}>
                    <span className='icon-wrapper'>
                        <i className={`bx ${icon}`} />
                    </span>
                    <h4  className="woocommerce-widget-title">{label}</h4>
                    <SVG
                        className={`${showItems ? "arrow-angle " : "arrow-angle rotate180"}`}
                        height={14}
                        width={14}
                        src={toAbsoluteUrl("/static/icons/up-arrow-angle.svg")}
                    />
                </div>
            }
            
            <div className={`list-items ${!mobile ? 'items-filter' : ''} ${!showItems && "hidden"}`}>
                {
                    (items === false) ? (
                        <CircleSpinner fullScreen={false} />
                    ) : (
                        !disableSearch &&
                        <div className="woocommerce-selected-items">
                            <input style={{marginTop:'1rem'}} type='text' onChange={(e)=>{handleInputChange(e)}} className='form-controla' placeholder='Search'/>
                        </div>
                    )
                }
                <ul className={`${mobile ? 'mb-3 bordered rounded' : 'collections-list-row'}`+`${!showItems ? "hidden" : ''}`} style={ !limit ? styleSidebar : {}}>
                    {
                        items.length > 0 && items.map((x, i)=> <li key={i} onClick={()=>handleChange(x)} className={
                            `${mobile ? 'pt-3 pb-3 pr-3 pl-3 bottom-bordered-not-last' : ''}`+
                            `${currentFilter.filter(y=>x.field===y.filterfield).length > 0 && findSelected(currentFilter.filter(y=>x.field===y.filterfield), x, false) ? ' is-active' : ''
                                }`
                        }>
                            {
                                !mobile && <span key={i} className='icon-wrapper'>
                                    {
                                            currentFilter.filter(y=>x.field===y.filterfield).length > 0 ? (
                                                findSelected(currentFilter.filter(y=>x.field===y.filterfield), x)
                                        ) : (
                                            <span className='icon-wrapper'>
                                                    <i className={`bx ${multi ? 'bx-checkbox' : 'bx-circle'}`}></i>
                                            </span>
                                        )
                                    }
                                </span>
                            }
                            
                            {
                                (x.label.includes('star')) ? ( 
                                    <span className='d-flex label align-items-center'>
                                        <StarRatings
                                            rating={parseInt(x.label.substr(0, 1), 10)}
                                            starRatedColor="rgb(255, 215, 0)"
                                            starEmptyColor='#dadada'
                                            starDimension="18px"
                                            starSpacing="0px"
                                            numberOfStars={5}
                                            name='rating'
                                        />
                                        & Up
                                    </span>
                                ) : (
                                    <span className='label'>{x.label}</span> 
                                )
                            }
                        </li>)
                    }
                </ul>
       
                {
                    mobile && currentFilter.filter(y=>field===y.filterfield).length > 0 && <button 
                    className='btn w-100 btn-purple mb-3'
                    onClick={
                        ()=>setFilter(null, null, null, {...currentFilter.find(y=>field===y.filterfield), multi: multi})
                    }
                >
                    Reset
                </button>
                }
            </div>
        </div>
    )
}
export default SidebarStaticRangeFilter;

