import React, { useState, useEffect } from 'react'
import StarRatings from 'react-star-ratings';
import { productService } from '../../services'
import CircleSpinner from '../../components/LoadingAnimation/CircleSpinner';
import cookie from 'js-cookie';
import { MY_LOCATION } from '../../helpers/constTypes'
import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl';
import SVG from "react-inlinesvg";
import WeightSanitizer from './../Common/WeightSanitizer'

const styleSidebar = {
    // maxHeight: '300px',
    // overflowY: 'auto',
    paddingRight: '0.5rem',
    marginRight: '-0.75rem'
}


const SidebarCheckFilter = ({filterfield,ctlers, data, addFilter, className, name, setUrl, keyName, checkedItems, source, sourceId, prechargedItems, disableSearch, filterType, type, icon, label, currentFilter, field, multi, locationRequired, showInitial=true, svgIcon}) => {

    const [ items, setItems ] = useState(false)
    const [ showItems, setShowItems ] = useState(showInitial)
    const [ showMore, setShowMore ] = useState(false)
    const [ limit, setLimit ] = useState(true)
    const [ search, setSearch ] = useState('')

    const handleChange = selectedOption => {
        
        addFilter({
            filterfield: selectedOption.field || filterfield, 
            filtertype: type, 
            filtervalue: selectedOption.value || selectedOption.slug, 
            filterlabel: selectedOption.label || selectedOption.slug,
            multi
        })
    };

    

    useEffect(() => {
        let value = search

        if(value.length > 0){
            let matches = prechargedItems.filter(obj => Object.values(obj).some(val => val.toLowerCase().includes(value.toLowerCase())));
            setItems(matches)
        } else {
            setItems(prechargedItems)
        }
    }, [search])



    useEffect(() => {
        let checkActive = prechargedItems.map((x, i) => {
            currentFilter.find(y => y.filterfield===x.field&&y.filtervalue===x.value)
        })
        // if(checkActive.length>0){
        //     setShowItems(true)
        // }
        let value = search

        if(value.length > 0){
            let matches = prechargedItems.filter(obj => Object.values(obj).some(val => val.toLowerCase().includes(value.toLowerCase())));
            setItems(matches)
        } else {
            setItems(prechargedItems)
        }
        
    }, [prechargedItems])


    useEffect(() => {
        if(items.length > 0){
            items.map((x, i) => {
                let b = currentFilter.find(y => y.filterfield===x.field&&y.filtervalue===x.value)
                if(b){
                    setShowItems(true)
                }
            })
        }

    }, [items])

    if(locationRequired && !cookie.get(MY_LOCATION)) return <></>
    return(
        <div className={`woocommerce-widget collections-list-widget pl-3 pr-3 ${className} `} >
        
            <div  className="woocommerce-widget-top" onClick={()=>{setShowItems(!showItems)}}>
                <span className='icon-wrapper'>
                    {
                        !svgIcon ? (
                            <i className={`bx ${icon}`} />
                        ) : (
                            <SVG 
                                src={toAbsoluteUrl(icon)}
                                width={20}
                            />
                        )
                    }
                    
                </span>
                <h4  className="woocommerce-widget-title">{label}</h4>
                <SVG
                    className={`${showItems ? "arrow-angle " : "arrow-angle rotate180"}`}
                    height={14}
                    width={14}
                    src={toAbsoluteUrl("/static/icons/up-arrow-angle.svg")}
                /> 
            </div>
            {
               showItems && prechargedItems.length > 6 && label.toLowerCase()!='weight' && <div className='filter-search-wrapper mb-2 '>
                    <input type='search' 
                        onChange={(e)=>setSearch(e.target.value)} 
                        name='filter-search' value={search} 
                        className='filter-search form-controla rounded' 
                        placeholder={`Search ${label}`}
                        value={search}
                    />
                    <i className='bx bx-search-alt'></i> 
                </div>
            }
            
            <div style={showMore ? {marginRight: '-5px', 
            // maxHeight: '300px', overflowY:'scroll'
            } : {}}  className={`list-items items-filter ${!showItems && "hidden"}`}>
            
                {
                    (items === false) ? (
                        <CircleSpinner fullScreen={false} />
                    ) : (
                        !disableSearch &&
                        <div className="woocommerce-selected-items">
                            <input style={{marginTop:'1rem'}} type='text' onChange={(e)=>{handleInputChange(e)}} className='form-controla' placeholder='Search'/>
                        </div>
                    )
                }
                <ul style={showMore ? {} : {}} className={`collections-list-row ${!showItems && "hidden"}`} >
                  
                    {
                        showMore ? (
                            
                                items.length > 0 && items.map((x, i)=> <li 
                                    key={i} 
                                    className={`${currentFilter.find(y => y.filterfield===x.field&&y.filtervalue===x.value) ? 'active' : ''} ${currentFilter.find(y => y.filterfield===filterfield&&y.filtervalue===x.slug) ? 'active' : ''} d-flex justify-content-between`} 
                                    onClick={()=>handleChange(x)}
                                >
                                   
                   
                                    {
                                        (x.label && x.label.includes('star')) ? ( 
                                            <span className='d-flex label align-items-center'>
                                                <StarRatings
                                                    rating={parseInt(x.label.substr(0, 1), 10)}
                                                    starRatedColor="rgb(255, 215, 0)"
                                                    starEmptyColor='#dadada'
                                                    starDimension="18px"
                                                    starSpacing="0px"
                                                    numberOfStars={5}
                                                    name='rating'
                                                />
                                                & Up
                                            </span>
                                        ) : (
                                            label.toLowerCase() === 'weight' 
                                            ? <span className='label'><WeightSanitizer type={x.type} unit={x.unit} _weight={x.weight} eachDetails /></span>
                                            : <span className='label'>{x.label || x.name}</span>
                                        )
                                    }
                                    <span key={i}>
                                        {
                                            currentFilter.find(y => y.filterfield===x.field&&y.filtervalue===x.value) || currentFilter.find(y => y.filterfield===filterfield&&y.filtervalue===x.slug) ? (
                                                
                                                    
                                                
                                                <span className='icon-wrapper'>
                                                    <i className={`bx ${multi ? 'bx-check' : 'bxs-circle'}`}></i>
                                                </span>
                                        ) : (
                                                <span className='icon-wrapper'>
                                                    <i className={`bx ${multi ? '' : 'bx-circle'}`}></i> 
                                                </span>
                                        )
                                    }
                                    </span>
                                </li>)
                            
                        ) : (
                            <>
                            
                                {
                                    items.length > 0 && items.slice(0, 6).map((x, i)=> <li 
                                        key={i} 
                                        className={`${currentFilter.find(y => y.filterfield===x.field&&y.filtervalue===x.value) ? 'active' : ''} ${currentFilter.find(y => y.filterfield===filterfield&&y.filtervalue===x.slug) ? 'active' : ''} d-flex justify-content-between`} 
                                        onClick={()=>handleChange(x)}
                                    >
                                       
                                        {
                                            (x.label && x.label.includes('star')) ? ( 
                                                <span className='d-flex label align-items-center'>
                                                    <StarRatings
                                                        rating={parseInt(x.label.substr(0, 1), 10)}
                                                        starRatedColor="rgb(255, 215, 0)"
                                                        starEmptyColor='#dadada'
                                                        starDimension="18px"
                                                        starSpacing="0px"
                                                        numberOfStars={5}
                                                        name='rating'
                                                    />
                                                    & Up
                                                </span>
                                            ) : (
                                                label.toLowerCase() === 'weight' 
                                                ? <span className='label'><WeightSanitizer type={x.type} unit={x.unit} _weight={x.weight} eachDetails /></span>
                                                : <span className='label'>{x.label || x.name}</span>
                                            )
                                        }

                                        {/* Only for deals with multifield */}
                                        
                                        {
                                           x.value && x.value.includes('percent_') && currentFilter.find(k => k.filterfield === 'toGet') && currentFilter.find(k => k.filterfield === 'type' && k.filtertype === 'eq' && k.filtervalue === '%OFF') ? (
                                                <span key={i}>
                                             
                                                    {
                                                        currentFilter.find(y => y.filterfield===x.field&&y.filtervalue===x.value.replace('percent_', '')) || currentFilter.find(y => y.filterfield===filterfield&&y.filtervalue===x.slug) ? (
                                                            <span className='icon-wrapper'>
                                                                <i className={`bx ${multi ? 'bx-check' : 'bxs-circle'}`}></i>
                                                            </span>
                                                        ) : (
                                                            <span className='icon-wrapper'>
                                                                <i className={`bx ${multi ? '' : 'bx-circle'}`}></i> 
                                                            </span>
                                                        )
                                                    }
                                                </span>
                                            ) : x.value && x.value.includes('currency_') && currentFilter.find(k => k.filterfield === 'toGet') && currentFilter.find(k => k.filterfield === 'type' && k.filtertype === 'eq' && k.filtervalue === '$OFF') ? (
                                                <span key={i}>
                                             
                                                    {
                                                        currentFilter.find(y => y.filterfield===x.field&&y.filtervalue===x.value.replace('currency_', '')) || currentFilter.find(y => y.filterfield===filterfield&&y.filtervalue===x.slug) ? (
                                                            <span className='icon-wrapper'>
                                                                <i className={`bx ${multi ? 'bx-check' : 'bxs-circle'}`}></i>
                                                            </span>
                                                        ) : (
                                                            <span className='icon-wrapper'>
                                                                <i className={`bx ${multi ? '' : 'bx-circle'}`}></i> 
                                                            </span>
                                                        )
                                                    }
                                                </span>
                                            ) : (<span key={i}>
                                                    {
                                                        currentFilter.find(y => y.filterfield===x.field&&y.filtervalue===x.value) || currentFilter.find(y => y.filterfield===filterfield&&y.filtervalue===x.slug) ? (
                                                            <span className='icon-wrapper'>
                                                                <i className={`bx ${multi ? 'bx-check' : 'bxs-circle'}`}></i>
                                                            </span>
                                                        ) : (
                                                            <span className='icon-wrapper'>
                                                                <i className={`bx ${multi ? '' : 'bx-circle'}`}></i> 
                                                            </span>
                                                        )
                                                    }
                                                </span>)
                                            
                                        }

                                        


                                    </li>)
                                }
                                
                                
                            </>
                        )
                        
                    }
                    {
                                    items.length > 6 && <div className='d-flex justify-content-start mt-2'>
                                        <button className='btn btn-link btn-small' style={{fontSize:'14px'}} onClick={()=>setShowMore(!showMore)}>{showMore ? 'Show Less' : 'Show More'}</button>
                                    </div>
                                } 
                </ul>
            </div>
        </div>
    )
}
export default SidebarCheckFilter;

